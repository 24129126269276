@use 'variable' as *;

.nav-social {
    float: right;
    margin-top: 7px;

    li {
        display: inline-block;
        padding-left: 1em;

        a {
            color: $input-text;
            text-decoration: none;

            &:hover {
                color: $title;
            }

            img,
            svg {
                height: 2em;
                opacity: 0.4;
                transition: 0.1s;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}

/*
*
* media-max: 55em
*
*/
@media (max-width: 55em) {
    nav {
        .nav-social {
            display: none;
            float: none;

            li {
                display: inline-block;
                margin: 2em 1em 1em;
                padding: 0;
            }
        }
    }
}
