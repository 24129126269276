@use 'variable' as *;

footer {
    border-top: 0.05em solid $nav-line;
    justify-content: flex-end;
    margin: 2em 0 2.5em;
    padding: 0 $width-large;
    padding-top: 1em;

    .row {
        margin: 0;
    }

    p {
        margin: 0 0.66em 3px 0;
    }
}

/*
*
* media-max: 73em
*
*/
@media (max-width: 73em) {
    footer {
        padding: 10px $width-middle;
    }
}

/*
*
* media-max: 55em
*
*/

@media (max-width: 55em) {
    footer {
        margin: 1em 0 0;
        padding: 10px $width-small;
    }
}
