@use 'variable' as *;

ul {
    margin: 0 0 1em 1.5em;

    li {
        color: $title;
        line-height: 1.6;
        list-style-image: url("../img/arrow-list.png");

        p {
            margin-bottom: 1em;
        }

        code {
            @media (max-width: 55em) {
                font-size: 0.8em;
            }
        }

        a,
        code {
            line-height: 1.5;
        }
    }
}
