@use 'variable' as *;
@use 'mixin';

.page-with-newsblog {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;

    aside {
        margin-left: 4vw;
        margin-right: 0;
        min-width: 22em;
        width: 22em;

        header {
            h1,
            h2,
            h3 {
                @include mixin.font-set(1.8em, $hover-color, 300);

                padding: 0;
            }
        }
    }

    .newsblog {
        border: 0.2em solid $nav-line;
        padding: 0 1.5em 2em;

        .aldryn-newsblog {
            border-bottom: 0.1em solid $nav-line;
            margin-bottom: 1.5em;
            padding-bottom: 0.45em;

            h2 {
                @include mixin.font-set(1.3em, $hover-color, 300);

                margin-bottom: 0.33em;
                margin-top: 1em;

                a {
                    color: $hover-color;
                    text-decoration: none;
                }
            }

            img {
                margin-top: 2em;
                max-width: 100%;
            }
        }
    }
}

/*
*
* media-max: 73em
*
*/
@media (max-width: 73em) {
    .container.page-with-newsblog {
        flex-direction: column;

        aside {
            margin-left: 0;
            width: 100%;
        }
    }
}
