$debug: #ccc;
$info: #cab999;
$warning: #f6d128;
$success: #32c518;
$error: #d9141e;
$nav-line: #dadada;
$hover-color: #015184;
$input: #a5a5a4;
$main: #003893;
$black: #000;
$white: #fff;
$title: #333;
$input-text: #666;
$border: #dadada;

$width-xlarge: 26.5vw;
$width-large: 14vw;
$width-middle: 4.66667vw;
$width-small: 1em;
$all-width: 100%;
