@use 'variable' as *;
@use 'mixin';

.menu-icon {
    display: none;
}

#closeMenu {
    display: none;
}

.page-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 1em $width-large;

    a {
        text-align: left;
    }

    .logo {
        height: 5.75em;
        max-width: 80%;
    }

    .search-form {
        display: flex;

        .search-input {
            appearance: none;
            border: 1px solid $input;
            border-right: 0;
            color: $input-text;
            padding: 9px 5px 9px 10px;
            transition: all 0.3s linear;
            width: 165px;
        }

        #search-button {
            appearance: none;
            background: $white;
            border: 1px solid $input;
            border-left: 0;
            cursor: pointer;
            padding-top: 5px;
            width: 35px;

            svg {
                fill: $input;
            }

            &:hover {
                background: $input;
            }

            &:hover svg {
                fill: $white;
            }
        }
    }
}

nav {
    border-bottom: 0.2em solid $nav-line;
    border-top: 0.2em solid $nav-line;
    margin-bottom: 3em;
    padding: 0 $width-large;

    #page-navigation {
        display: inline-block;

        .active {
            box-shadow: (0, 0.2em, 0, 0, $hover-color);
        }

        li {
            display: inline-block;
            line-height: 3;
            margin-right: 1em;

            a {
                @include mixin.font-set(1em, $title, bold);

                &:hover {
                    color: $hover-color;
                }
            }
        }
    }
}

ul#page-navigation > li.active a {
    color: $hover-color;
}

/*
*
* media-max: 73em
*
*/
@media (max-width: 73em) {
    nav {
        padding: 0 $width-middle;
    }

    .page-header {
        margin: 1em $width-middle;
    }
}

/*
*
* media-max: 55em
*
*/
@media (max-width: 55em) {
    .page-header {
        margin: $width-small;

        .search-form {
            .search-input {
                font-size: 1.2em;
                width: calc(100% - 35px);
            }
        }
    }

    nav {
        border: 0;
        margin: 1em;
        padding: 0;

        #page-navigation {
            display: none;

            .active {
                box-shadow: (-0.2em, 0, 0, 0, $hover-color);
            }

            li {
                display: block;
                line-height: 3;
                margin-right: 1em;
                padding: 0 0 0 1em;

                a {
                    @include mixin.font-set(1em, $title, bold);
                }
            }
        }
    }

    .show-body nav .page-navigation {
        display: block;
    }

    .show-body nav .nav-social {
        display: flex;
        justify-content: space-around;
    }

    #openMenu.hide-icon {
        display: none;
    }

    #closeMenu.show-icon {
        display: block;
    }

    .menu-icon {
        display: block;
    }
}

.lang.active {
    display: none;
}
