@use 'variable' as *;
@use 'mixin';

html {
    font-family: 'Roboto', sans-serif;
    font-size: 95%;
}

h1 {
    @include mixin.font-set(2.31em, $hover-color, bold);
}

h2 {
    @include mixin.font-set(1.4em, $hover-color, 300);

    text-align: left;
}

h3 {
    @include mixin.font-set(1.25em, $title, bold);
}

h1,
h2,
h3 {
    line-height: 1.33em;
    margin-bottom: 0.66em;
}

h4,
h5,
h6 {
    @include mixin.font-set(1.1em, $title, bold);

    line-height: 1.5;
    padding: 0 0 1em;
}

h5 {
    padding: 0;
}

a {
    @include mixin.linx($hover-color, $title, $title);

    color: $title;
    text-align: center;
    text-decoration: underline;

    &:hover {
        color: $hover-color;
    }
}

p {
    @include mixin.font-set(1em, $title, 300);

    line-height: 1.55;
    margin-bottom: 1em;
}

strong {
    font-weight: bold;
}

pre {
    @include mixin.font-set(0.8em, $input-text, 300);

    padding: 0 0 1.6em;
}

ol {
    @include mixin.font-set(1em, $title, 300);

    line-height: 1.55;
    list-style-type: decimal;
    margin: 0 0 1em 1.5em;
}

.container img {
    margin: 2em 0;
    max-width: 100%;
}

body.show-body {
    background: $nav-line;
}

ul#page-navigation {
    margin: 0;
}

body.show-body #page-navigation {
    display: block;
}

#clon-submenu {
    display: none;

    @media (max-width: 55em) {
        display: block;
        margin: 1em;
    }

    ul {
        border-bottom: 0.05em solid $nav-line;
        margin: 0;

        li {
            list-style-image: none;

            a {
                @include mixin.font-set(1em, $title, bold);

                display: inline-block;
                line-height: 1;
                padding: 1em;

                &.active_link {
                    box-shadow: (-0.1em, 0, 0, 0, $error);

                    color: $error;
                }
            }
        }
    }
}

.container {
    @media (min-width: 1169px) {
        max-width: calc(#{$all-width} - #{$width-xlarge});
    }

    @media (max-width: 1168px) {
        max-width: calc(#{$all-width} - #{$width-middle});
    }
}

body.show-body .container,
body.show-body footer,
body.show-body #clon-submenu {
    display: none;
}

.display-flex {
    align-items: center;
    display: flex;
}

table {
    margin: 2em 0;

    caption {
        padding-bottom: 0.5em;
    }
}

th,
td {
    border: 1px solid $border;
    line-height: 1.6;
    padding: 0.4em;
}
