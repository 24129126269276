/*
    Colorize DjangCMS toolbar to detect the type of deployment.

    <body class="site-devel is-homepage">
        <div id="cms-top">
            <div class="cms-toolbar">
                <div class="cms-toolbar-left">
                    <div class="cms-toolbar-item cms-toolbar-item-logo">
                        <a>djangoCMS</a>
*/

@mixin toolbar($content, $color, $background_color) {
    div.cms-toolbar {
        background-color: $background_color;

        div.cms-toolbar-left {
            div.cms-toolbar-item-logo a {
                color: $color;

                &::after {
                    content: $content;
                }
            }
        }
    }
}

/*
Site codes:

    - unknown       E020 - puzzle
    - dev           E00C - eye
    - devel         E00C - eye
    - devel-docker
    - test-web-b    \E011 (i)
    - test-web-r
    - test-web-s
    - test-dev      E012 - wheel
    - prod-b        E007 thick box
    - prod-r
    - prod-s
*/

body.site-unknown div#cms-top {
    @include toolbar(" \E020  Unknown", #6e6e6e, white);
}

body.site-devel div#cms-top {
    @include toolbar(" \E00C  Devel", black, white);
}

body.site-devel-docker div#cms-top {
    @include toolbar(" \E00C  Devel in Docker", black, #f7fcb1);
}

body.site-test-web-b div#cms-top {
    @include toolbar(" \E011  Test B", #ff0000, #f6c393);
}

body.site-test-web-r div#cms-top {
    @include toolbar(" \E011  Test R", #ff0000, #f6c393);
}

body.site-test-web-s div#cms-top {
    @include toolbar(" \E011  Test S", #ff0000, #f6c393);
}

body.site-dev div#cms-top {
    @include toolbar(" \E012  Test DEV", #ff8800, #fde5cf);
}

body.site-prod-b div#cms-top {
    @include toolbar(" \E007  Production! B", #0e5d00, #9ff693);
}

body.site-prod-r div#cms-top {
    @include toolbar(" \E007  Production! R", #0e5d00, #9ff693);
}

body.site-prod-s div#cms-top {
    @include toolbar(" \E007  Production! S", #0e5d00, #9ff693);
}

body.site-production div#cms-top {
    @include toolbar(" \E007  Production!", #0e5d00, #9ff693);
}
