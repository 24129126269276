@use 'variable' as *;

.aldryn-newsblog-latest-articles {
    article {
        padding-top: 1.5em;

        h2 {
            a {
                color: $main;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.aldryn-newsblog-list {
    article {
        border-bottom: 0.1em solid $nav-line;
        padding-bottom: 1.5em;

        h2:not(:first-child) {
            margin-top: 0.5em;
        }

        &:last-of-type {
            border: 0;
        }
    }
}

.is-homepage {
    .container.page-with-newsblog {
        justify-content: flex-end;

        aside {
            width: 25%;
        }
    }
}

.aldryn-newsblog-article {
    ul {
        margin-bottom: 1em;
    }

    li {
        line-height: 1.5;
    }

    .author {
        margin: 0;
    }
}

/*
*
* media-max: 73em
*
*/
@media (max-width: 73em) {
    .is-homepage {
        .container.page-with-newsblog {
            aside {
                width: 100%;
            }
        }
    }
}
