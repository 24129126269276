// --------------------------------------------------
// Font - size, color, weight
// --------------------------------------------------
@mixin font-set($fs, $c, $fw) {
    color: $c;
    font-size: $fs;
    font-weight: $fw;
    text-decoration: none;
}

// --------------------------------------------------
// link - hover, avtive, visited
// --------------------------------------------------
@mixin linx($hover, $active, $visit) {
    &:hover {
        color: $hover;
    }

    &:active {
        color: $active;
    }

    &:visited {
        color: $visit;
    }
}

// Flex $grow, $shrink, $width
@mixin flex-gsw($grow, $shrink, $width) {
    flex-basis: $width;
    flex-grow: $grow;
    flex-shrink: $shrink;
}
