@use 'variable' as *;
@use 'mixin';

/*
*
* aldryn-newsblog-pagination
*
*/
.djangocms-newsblog-content {
    ul.pagination {
        li {
            display: inline-block;
            margin-right: 1em;

            a {
                @include mixin.font-set(1em, $title, 300);

                border: 1px solid $title;
                display: inline-block;
                line-height: 1.55em;
                min-width: 1.55em;
                padding: 3px 5px;

                &:hover {
                    border: 1px solid $error;
                    color: $error;
                }
            }
        }

        li.active span {
            @include mixin.font-set(1em, $title, 300);

            display: inline-block;
            line-height: 1.55em;
            min-width: 1.55em;
            text-align: center;
        }
    }
}
